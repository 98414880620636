<script setup lang="ts">
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/vue-splide'
import { SlideProps } from '../components/StoriesSlide.vue'
import { MediaImageFragment, MediaRemoteVideo, ParagraphStories, ParagraphStoryItem } from '~/api/graphql/generated'

const props = defineProps<{ entity: ParagraphStories }>()

const type = computed(() => props.entity.fieldBackgroundColor || 'light-blue')
const title = computed(() => props.entity.fieldTitle)
const description = computed(() => props.entity.fieldDescription?.processed)

const link = computed(() => ({
  url: props.entity.fieldLink?.url?.path || '',
  text: props.entity.fieldLink?.title || '',
  target: props.entity.fieldLink?.options.attributes?.target || '_self',
}))

const slides = computed(() => {
  return (
    props.entity.fieldStoryItem?.map((el) => {
      const item = el?.entity as ParagraphStoryItem
      const video = item.fieldVideo?.entity as MediaRemoteVideo
      return {
        type: item.fieldStoryType || 'normal',
        quote: item.fieldQuote?.processed || '',
        image: item.fieldImage?.entity as MediaImageFragment,
        video: video?.fieldMediaOembedVideo ?? '',
        name: item.fieldName || '',
        position: item.fieldPosition || '',
      }
    }) || []
  )
})

const options = {
  autoWidth: true,
  gap: 12,
  trimSpace: false,
  mediaQuery: 'min',
  arrows: false,
  breakpoints: {
    1024: {
      pagination: false,
      arrows: true,
      gap: 0,
    },
  },
} as Options
</script>

<template>
  <Section large wide :class="`stories-slider type-${type}`">
    <div class="container grid">
      <div v-animate="{ animation: 'vertical-reveal' }" class="background" />
      <div class="tl-5 tl-o-1">
        <h2 v-if="title" class="text-xlarge">
          {{ title }}
        </h2>
        <div v-if="description" class="description text-medium" v-html="description" />
        <Button
          v-if="link.url"
          secondary
          icon="arrow-left"
          slide
          :to="link.url"
          :target="link.target"
          class="main-button"
        >
          {{ link.text }}
        </Button>
      </div>
      <div class="tl-6">
        <Splide :options="options" :has-track="false" aria-label="My Favorite Images">
          <SplideTrack class="splide-track">
            <SplideSlide
              v-for="(slide, index) in slides"
              :key="index"
              v-animate="{ animation: 'vertical-reveal' }"
              class="splide-slide"
            >
              <StoriesSlide :entity="slide" />
            </SplideSlide>
          </SplideTrack>
          <div v-animate="{ animation: 'vertical-reveal' }" class="splide__arrows">
            <ButtonNavigation class="splide__arrow--prev" />
            <ButtonNavigation class="splide__arrow--next" direction="right" />
          </div>
        </Splide>
      </div>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
.stories-slider {
  --padding: 60px;
  --background-color: var(--c-red-dark-100);
  --text-color: var(--c-white);

  position: relative;
  overflow: hidden;

  color: var(--text-color);

  @include breakpoint(tl) {
    --padding: 160px;
  }

  h2 {
    color: inherit;
  }

  .description {
    color: inherit;
    > :deep(*) {
      margin-bottom: 0;
    }
  }

  &.type-light-blue {
    --background-color: var(--c-background-medium);
    --text-color: var(--c-text-dark);

    h2 {
      color: var(--c-primary);
    }

    .splide__arrows {
      :deep(.button) {
        --color-border: var(--c-primary);
        --color: var(--c-primary);
        --color-hover: var(--c-primary);
        --color-border-hover: var(--c-primary);
      }
    }
  }

  &.type-dark-blue {
    --background-color: var(--c-primary);
  }

  &.type-petrol {
    --background-color: var(--c-green-dark-100);
  }

  .background {
    @include fill;
    width: 100vw;
    left: 50%;
    translate: -50%;
    z-index: -1;

    background: var(--background-color);
    @include breakpoint(tl) {
      width: 100%;
    }
  }

  .main-button {
    margin-top: 50px;
  }
}

.grid {
  row-gap: 50px;
  align-items: center;

  position: relative;
  padding-block: var(--padding);
}

.splide__track {
  --mr: 0;

  overflow: visible;

  margin-right: var(--mr);
  width: var(--sides-width);

  @include breakpoint(tl) {
    overflow: hidden;

    --sides-width: max(
      0px,
      calc(
        min(
          100vw,
          (var(--container-max-width) / 2) + (var(--layout-max-width) - var(--container-max-width)) / 2 -
            (var(--gap) / 2)
        )
      )
    );

    --mr: calc(((var(--sides-width) / 2) + var(--layout-padding)) * -1);
  }
}

.splide-slide {
  --padding-inline: 0px;
  --padding-block: 0px;
  --height: 490px;

  padding: var(--padding-block) var(--padding-inline);

  width: 100%;
  min-height: calc(var(--height) + 2 * var(--padding-block));
  max-width: calc(480px + var(--padding-inline));

  @include breakpoint(tl) {
    --padding-inline: 12px;
    --padding-block: 20px;
    --height: 520px;
  }
}

.splide__arrows {
  position: absolute;
  right: 48px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  margin-top: 20px;

  :deep(.button) {
    --color-border: var(--c-white);
    --color: var(--c-white);
    --color-hover: var(--c-white);
    --color-border-hover: var(--c-white);
  }
}

.splide :deep(.splide__pagination) {
  position: static;
  margin-top: 35px;

  .splide__pagination__page {
    &.is-active {
      background: var(--c-white);
    }
  }
}
</style>
